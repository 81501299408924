<template>
  <a-input-number
    v-bind="record.options"
    :value="value"
    @change="change"
    @pressEnter="pressEnter"
  />
</template>

<script>
import formEleMixin from './form-ele-mixin'
export default {
  name: 'AxInputNumber',
  mixins: [formEleMixin],
  methods: {
    /**
     * 变化回调
     * @param value
     */
    change (value) {
      this.$emit('change', value, this.record.model)
    },
    /**
     * 按下回车的回调
     * @param e
     */
    pressEnter (e) {
      this.$emit('pressEnter', e.target.value, this.record.model)
    }
  }
}
</script>
